import React, {
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import {
  verifyOtpGoogleApi,
  verifyOTP,
  sendOtpEmail,
  checkLogin,
} from "../../api/getData";
import { useGetOTPFireBase } from "../../hooks/useGetOTPFireBase";
import {
  replacePhoneFirebase,
  validateEmail,
  validatePhone,
} from "../../utils/common";
import Modal from "../Modal";
import "./index.css";
import { toast } from "react-toastify";

const TYPE = {
  PHONE: "phone",
  EMAIL: "email",
};

const InfoInput = ({
  name,
  setIsValidOTP,
  selectedOption,
  setSelectedOption,
  username,
  setUsername,
}: {
  name?: string;
  setIsValidOTP: Dispatch<SetStateAction<boolean>>;
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;
}) => {
  const [validate, setValidate] = useState("");
  const [valOtp, setValOtp] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const verifyCaptcha = useGetOTPFireBase(
    username,
    selectedOption === TYPE.PHONE,
    setIsOpenModal
  );

  useEffect(() => {
    if (!isOpenModal) setUsername("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleClickRegister = () => {
    if (!username) {
      setValidate(
        `Vui lòng nhập ${
          selectedOption === "phone"
            ? "số điện thoại"
            : "địa chỉ Email"
        }`
      );
      return;
    }
    if (selectedOption === TYPE.PHONE) {
      if (
        validate.length === 0 &&
        username.length !== 0 &&
        verifyCaptcha
      )
        setIsOpenModal(true);
    } else {
      if (!validate) {
        const sendOTP = sendOtpEmail(username);
        sendOTP
          .then((res) => {
            if (res.data?.data?.enterprise_get_otp) {
              setIsOpenModal(true);
            } else if (
              res.data?.errors[0]?.message ===
              "EMAIL_EXISTED"
            ) {
              toast.error(
                "Tài khoản không đúng hoặc đã tồn tại",
                {
                  theme: "colored",
                }
              );
              setIsOpenModal(false);
            } else {
              toast.error("Có lỗi xảy ra", {
                theme: "colored",
              });
              setIsOpenModal(false);
            }
          })
          .catch((error) => {});
      }
    }
  };

  // useEffect(() => {
  //   if (!isOpenModal)
  // }, [isOpenModal]);

  const onSubmitOTP = async () => {
    if (selectedOption === TYPE.PHONE) {
      try {
        const resOTPCaptcha = await verifyOtpGoogleApi({
          code: valOtp,
          phoneNumber: replacePhoneFirebase(username),
          sessionInfo: verifyCaptcha,
        });
        checkLogin(username).then((res) => {
          if (
            res?.data?.data?.check_ident_to_signup?.status
          ) {
            setIsValidOTP(resOTPCaptcha?.data);
            setIsOpenModal(false);
            toast.success(
              "Mời bạn nhập thông tin doanh nghiệp",
              {
                theme: "colored",
              }
            );
            localStorage.setItem(
              "isValidOTP",
              resOTPCaptcha?.data
            );
          } else {
            setIsOpenModal(false);
            toast.error("Số điện thoại đã được đăng ký", {
              theme: "colored",
            });
          }
        });
      } catch (error) {
        toast.error("Nhập mã OTP không chính xác", {
          theme: "colored",
        });
      }
    } else {
      try {
        const resOTP = await verifyOTP({
          username,
          otp: valOtp,
        });

        console.log(resOTP.data);
        if (
          resOTP.data?.data?.enterprise_verify_otp
            ?.message === "INVALID_CODE"
        ) {
          toast.error("Nhập mã OTP không chính xác", {
            theme: "colored",
          });
        } else {
          toast.success(
            "Mời bạn nhập thông tin doanh nghiệp",
            {
              theme: "colored",
            }
          );
          setIsValidOTP(resOTP?.data);
          localStorage.setItem("isValidOTP", resOTP.data);
          setIsOpenModal(false);
        }
        // checkLogin(username).then((res) => {
        //   if (
        //     res?.data?.data?.check_ident_to_signup?.status
        //   ) {
        //     toast.success(
        //       "Mời bạn nhập thông tin doanh nghiệp",
        //       {
        //         theme: "colored",
        //       }
        //     );
        //     setIsValidOTP(resOTP?.data);
        //     localStorage.setItem("isValidOTP", resOTP.data);
        //     setIsOpenModal(false);
        //   } else {
        //     setIsOpenModal(false);
        //     toast.error("Email đã được đăng ký", {
        //       theme: "colored",
        //     });
        //   }
        // });
      } catch (error) {}
    }
  };

  const handleChangeTab = (tab: string) => {
    setSelectedOption(tab);
    setUsername("");
    setValidate("");
  };

  return (
    <div className="form-info">
      <div className="choose-option">
        <div
          className={
            selectedOption === TYPE.PHONE
              ? "option-selected"
              : "option"
          }
          onClick={() => handleChangeTab(TYPE.PHONE)}
        >
          Số điện thoại
        </div>
        <div
          className={
            selectedOption === TYPE.EMAIL
              ? "option-selected"
              : "option"
          }
          onClick={() => handleChangeTab(TYPE.EMAIL)}
        >
          Email address
        </div>
      </div>
      <div>
        <input
          id="input"
          type="text"
          className="inputRef"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setValidate(
              selectedOption === TYPE.PHONE
                ? validatePhone(e.target.value)
                : validateEmail(e.target.value)
            );
          }}
          placeholder={
            selectedOption === TYPE.EMAIL
              ? "Email"
              : "Số điện thoại"
          }
        />
        <div className="validate">{validate}</div>
      </div>
      {!verifyCaptcha && username?.length === 10 && (
        <div
          id="recaptcha-container"
          style={{ margin: "20px 0" }}
        ></div>
      )}

      <div className="refNameField">
        {`${name ? `Người giới thiệu: ${name}` : ""}`}
      </div>
      <button
        className="btn-signup"
        onClick={handleClickRegister}
      >
        Đăng ký
      </button>
      {isOpenModal && (
        <Modal
          option={selectedOption}
          username={username}
          handleCancelPopup={handleCloseModal}
          onSubmitOTP={onSubmitOTP}
          setValOtp={setValOtp}
        />
      )}
    </div>
  );
};

export default InfoInput;
