import React, { useState } from "react";
import "./index.css";
import OtpInput from "react18-input-otp";
function Modal({
  option,
  username,
  handleCancelPopup,
  onSubmitOTP,
  setValOtp,
}: any) {
  const [otp, setOtp] = useState<string>("");
  const handleChangeOtp = (val: any) => {
    setOtp(val);
    setValOtp(val);
  };

  return (
    <div className="modalWrapper">
      <div className="modalContent">
        <div className="closeOTP" onClick={handleCancelPopup}>
          <img src="/icon_close.svg" width={16} height={16} alt="icon-close" />
        </div>
        <div className="modal-title">Xác thực OTP</div>
        <div className="modal-body">
          <div>
            {`Chúng tôi đã gửi mã xác thực đến ${
              option === "phone" ? "số điện thoại" : "email"
            } ${username}. Vui lòng kiểm tra và
            nhập mã OTP đã nhận được`}
          </div>
          <div className="otp-input">
            <OtpInput
              value={otp}
              onChange={handleChangeOtp}
              numInputs={6}
              separator={<span>-</span>}
              className="otp"
              isInputNum={true}
            />
          </div>
        </div>
        <button
          className="modal-footer"
          onClick={onSubmitOTP}
          disabled={otp.length !== 6}
        >
          Xác nhận
        </button>
      </div>
    </div>
  );
}

export default Modal;
