import React, { useEffect } from 'react'
import { useState, useRef } from 'react'
import './index.css'
import axios from 'axios';
import Loading from '../Loading';
const FileUpload = ({ onChange, setErrorStyle, title, error }) => {

    const [imgData, setImgData] = useState(null);
    const [loading, setLoading] = useState(false)
    const inputRef = useRef(null)
    const onChangePicture = async (e) => {
        setImgData(null)
        if (!!e.target.files.length) {
            try {
                const response = await axios({
                    method: "post",
                    url: process.env.REACT_APP_MOBILE_API + '/api/v2/upload-service/file',
                    data: { file: e.target.files[0] },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setLoading(true)
                await new Promise(res => setTimeout(res, 1000))
                setImgData(
                    response.data?.url + response.data?.path
                );
                onChange(response.data?.path)
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        }
    };
    return (
        <div >
            <label>
                {title}
            </label>
            <input
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={onChangePicture}
                ref={inputRef}
                style={{ ...setErrorStyle(), display: 'none' }}

            />
            <div className="previewProfilePic" onClick={() => inputRef.current?.click()} style={{
                cursor: 'pointer'
            }}>
                {
                    imgData ? <img className="imageUpload" src={imgData} /> : <div className='iconUpload'>
                        {
                            loading ? <Loading /> : <img src='camera.png' />
                        }
                    </div>
                }


            </div>
            <div className="validate">
                {error &&
                    error?.message}
            </div>
        </div>
    )
}

export default FileUpload