import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { registerEnterprise } from "../../api/getData";
import { translateError } from "../../common/translate";
import MapRegisterNFT from "../Map";

import "./index.css";
import instanceAxios from "../../api/axiosConfig";
import axios from "axios";
import FileUpload from "../FileUpload";
const setErrorStyle = (name) => {
  return {
    borderColor: name ? "red" : "",
    boxShadow: name ? "0 0 1.5px 1px red" : "",
  };
};
const Register = ({ id, username, option }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
  } = useForm();

  const ref = useRef("");
  const [typeIdentity, setTypeIndentity] = useState("1");
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const setPos = async (pos) => {
    ref.current = "changepos";
    // setValue({

    //   address: pos?.name,
    //   lat: pos?.lat,
    //   long: pos?.long,
    // });
    setValue("address", pos?.name);
    setValue("lat", pos?.lat);
    setValue("long", pos?.long);
  };

  const onSubmit = (data, e) => {
    delete data.confirm_password;
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        delete data[key];
      }
    });
    console.log(data);
    const payload = { ...data, invite_code: id };
    // console.log(payload);
    registerEnterprise(payload)
      .then((res) => {
        if (res?.data?.errors?.length > 0) {
          res?.data?.errors?.forEach((err) => {
            toast.error(translateError(err?.message), {
              theme: "colored",
            });
          });
        } else {
          toast.success("Đăng ký thành công", {
            theme: "colored",
          });
          localStorage.removeItem("isValidOTP");
          reset();
          setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_URL_WEB_CMS}`;
          }, 2000);
        }
      })
      .catch(() => {
        toast.error("Có lỗi xảy ra", {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    if (option === "phone") {
      setValue("phone", username);
    }
    if (option === "email") {
      setValue("email", username);
    }
  }, [username, option]);
  return (
    <form
      className="register-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          return false;
        }
      }}
    >
      <div className="form-item">
        <label htmlFor="name" className="input-label">
          Tên doanh nghiệp
        </label>
        <div className="form-item-direction">
          <input
            className="input-item"
            {...register("name", {
              required: "Vui lòng nhập",
            })}
            style={setErrorStyle(errors.name)}
          />
          <div className="validate">
            {errors.name && errors.name.message}
          </div>
        </div>
      </div>
      <div className="form-item">
        <label htmlFor="phone" className="input-label">
          Số điện thoại:
        </label>
        <div className="form-item-direction">
          <input
            className="input-item"
            disabled={option === "phone" && username}
            style={setErrorStyle(errors.phone)}
            type="number"
            aria-controls={false}
            {...register("phone", {
              required: "Vui lòng nhập",
              pattern: {
                value: /^(0[3|5|7|8|9])+([0-9]{8})+$\b/g,
                message:
                  "Vui lòng nhập đúng định dạng số điện thoại",
              },
            })}
          />
          <div className="validate">
            {errors.phone && errors.phone.message}
          </div>
        </div>
      </div>
      <div className="form-item">
        <label htmlFor="email" className="input-label">
          Email:
        </label>
        <div className="form-item-direction">
          <input
            className="input-item"
            style={setErrorStyle(errors.email)}
            type="text"
            disabled={option === "email" && username}
            {...register("email", {
              required: "Vui lòng nhập",
              pattern: {
                value:
                  /^[a-zA-Z0-9-._]{4,}@[a-zA-Z0-9-_]+\.(vn|com|com.vn|net)$/g,
                message:
                  "Vui lòng nhập đúng định dạng email",
              },
            })}
          />
          <div className="validate">
            {errors.email && errors.email.message}
          </div>
        </div>
      </div>
      <div className="form-item">
        <label htmlFor="password" className="input-label">
          Mật khẩu:
        </label>

        <div className="form-item-direction-password">
          <input
            className="input-item"
            style={setErrorStyle(errors.password)}
            name="password"
            id="password"
            type={showPass ? "text" : "password"}
            {...register("password", {
              required: "Vui lòng nhập",
              pattern: {
                value: /^.{8,}$/,
                message: "Mật khẩu phải từ 8 đến 32 kí tự",
              },
            })}
          />
          <div className="validate">
            {errors.password && errors.password.message}
          </div>
          <img
            src={showPass ? "/view.png" : "hidden.png"}
            onClick={() => {
              ref.current = "showpass";
              setShowPass(!showPass);
            }}
            className="eye-show"
            alt="eye"
          />
        </div>
      </div>
      <div className="form-item">
        <label
          htmlFor="confirm_password"
          className="input-label"
        >
          Nhập lại mật khẩu:
        </label>
        <div className="form-item-direction-password">
          <input
            className="input-item"
            type={showRePass ? "text" : "password"}
            id="confirm_password"
            style={setErrorStyle(errors.confirm_password)}
            {...register("confirm_password", {
              required: "Vui lòng nhập",
              validate: (val) => {
                if (watch("password") != val) {
                  return "Vui lòng nhập lại chính xác mật khẩu";
                }
              },
            })}
          />
          <div className="validate">
            {errors.confirm_password &&
              errors.confirm_password.message}
          </div>
          <img
            src={showRePass ? "/view.png" : "hidden.png"}
            onClick={() => {
              ref.current = "showpass";
              setShowRePass(!showRePass);
            }}
            className="eye-show-repass"
            alt="eye"
          />
        </div>
      </div>
      <div className="form-item-location">
        <label htmlFor="map" className="input-label">
          Địa chỉ:
        </label>
        <div className="map-view">
          <MapRegisterNFT
            listLocation={[]}
            setPos={setPos}
            refCurrentMap={ref.current}
          />

          <div className="form-item-address">
            <label
              htmlFor="address"
              className="input-label"
            >
              Tên địa điểm:
            </label>
            <div className="form-item-direction">
              <input
                className="input-item"
                style={setErrorStyle(errors.address)}
                {...register("address", {
                  required: "Vui lòng nhập",
                })}
              />
              <div className="validate">
                {errors.address && errors.address.message}
              </div>
            </div>
          </div>
          <div className="form-item-latlong">
            <label htmlFor="lat">Lat: </label>

            <input
              disabled
              className="input-item"
              style={setErrorStyle(errors.lat)}
              {...register("lat", {
                required: "Vui lòng nhập",
              })}
            />
            <div className="validate">
              {errors.lat && errors.lat.message}
            </div>
            <label htmlFor="long">Long: </label>

            <input
              disabled
              className="input-item"
              style={setErrorStyle(errors.long)}
              {...register("long", {
                required: "Vui lòng nhập",
              })}
            />
            <div className="validate">
              {errors.long && errors.long.message}
            </div>
          </div>
        </div>
      </div>

      <div className="enterprise-file-upload">
        <Controller
          control={control}
          name="enterprise_image"
          rules={{
            required: "Vui lòng chọn file",
          }}
          render={({
            field: { onChange },
            fieldState: { error },
          }) => (
            <FileUpload
              setErrorStyle={setErrorStyle}
              onChange={onChange}
              title="Ảnh doanh nghiệp"
              error={errors["enterprise_image"]}
            />
          )}
        />
        <Controller
          control={control}
          name="license_image"
          rules={{
            required: "Vui lòng chọn file",
          }}
          render={({
            field: { onChange },
            fieldState: { error },
          }) => (
            <FileUpload
              setErrorStyle={setErrorStyle}
              onChange={onChange}
              title="Ảnh đăng ký kinh doanh"
              error={errors["license_image"]}
            />
          )}
        />
      </div>
      <div>
        <span s={typeIdentity}>Giấy tờ của chủ quán</span>
        <select
          className="identitySelect"
          defaultValue={"1"}
          onChange={(e) => setTypeIndentity(e.target.value)}
        >
          <option value={"1"}>CCCD/CMND</option>
          <option value={"2"}>Hộ chiếu</option>
        </select>
      </div>

      {typeIdentity === "1" ? (
        <div className="enterprise-file-upload">
          <Controller
            control={control}
            name="identity_front"
            rules={{
              required: "Vui lòng chọn file",
            }}
            render={({
              field: { onChange },
              fieldState: { error },
            }) => (
              <FileUpload
                setErrorStyle={setErrorStyle}
                onChange={onChange}
                title="Mặt trước"
                error={errors["identity_front"]}
              />
            )}
          />
          <Controller
            control={control}
            name="identity_backside"
            rules={{
              required: "Vui lòng chọn file",
            }}
            render={({
              field: { onChange },
              fieldState: { error },
            }) => (
              <FileUpload
                setErrorStyle={setErrorStyle}
                onChange={onChange}
                title="Mặt sau"
                error={errors["identity_backside"]}
              />
            )}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <Controller
            control={control}
            name="passport_image"
            rules={{
              required: "Vui lòng chọn file",
            }}
            render={({
              field: { onChange },
              fieldState: { error },
            }) => (
              <FileUpload
                setErrorStyle={setErrorStyle}
                onChange={onChange}
                title="Ảnh hộ chiếu"
                error={errors["passport_image"]}
              />
            )}
          />
        </div>
      )}

      <div className="btn-register-container">
        <button className="btn-register" type="submit">
          Đăng ký
        </button>
      </div>
    </form>
  );
};

export default Register;
