export const translateError = (message: string) => {
  switch (message) {
    case "PHONE OR EMAIL ALREADY IN USE": {
      return "Số điện thoại hoặc email đã được sử dụng";
    }
    default: {
      return "Có lỗi xảy ra";
    }
  }
};
