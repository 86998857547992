/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from "react";
import style from "./map.module.scss";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
// import classNames from "classnames";
// import InfoWindow = google.maps.InfoWindow;
// import { toLowerCaseNonAccentVietnamese } from "@/utils/common";
export interface MapProps {
  id: number;
  lat: number;
  long: number;
  status: string;
  commune: string;
  country: string;
  district: string;
  map_captured: string;
  name: string;
  province: string;
  street: string;
  user_full_name: string;
  is_blacklist: boolean;
  user_id: any;
  user_name_owner: any;
}

interface PredictionsProps {
  place_id: string;
  description: string;
}

enum MarkerType {
  BlackList,
  Owned,
  Approved,
  Pinned = -1,
}

class MarkerInfo {
  info: google.maps.InfoWindow;
  type: MarkerType;
  marker?: google.maps.Marker;
  constructor(
    infoWindow: google.maps.InfoWindow,
    marker: google.maps.Marker | undefined,
    location: any
  ) {
    this.info = infoWindow;
    this.type = checkTypeLocation(location, marker);

    this.marker = marker;
  }
}

const checkTypeLocation = (location: MapProps, marker?: any): MarkerType => {
  if (location.is_blacklist) {
    return MarkerType.BlackList;
  }

  if (
    location.user_full_name?.length ||
    location.user_id ||
    location.user_name_owner
  ) {
    return MarkerType.Owned;
  }
  if (marker === undefined) {
    return MarkerType.Pinned;
  }

  return MarkerType.Approved;
};

function MapRegisterNFT({
  current,
  setPos,
  listLocation,
  zoom,
  refCurrentMap,
}: {
  current?: any;
  setPos?: any;
  listLocation?: any;
  zoom?: number;
  refCurrentMap?: string;
}): JSX.Element {
  const [listMarkerInfos] = useState([] as MarkerInfo[]);

  //search map custom
  const refDataPrediction = useRef<any>();
  const [currentLocation, setCurrentLocation] = useState({
    lat: 21.0020772,
    lng: 105.8065682,
  });
  const [text, setText] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [placeService, setPlaceService] = useState();
  const [googleMap, setGoogleMap] = useState();
  const [markerSearch, setMarkerSearch] = useState();
  const [place, setPlace] = useState();
  const autocompleteService = new google.maps.places.AutocompleteService();
  const useOutsideAlerter = (ref: any, handler: any) => {
    useEffect(() => {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      // document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        // document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };

  useOutsideAlerter(refDataPrediction, () => {
    setPredictions([]);
  });

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (refCurrentMap === "") init(place);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLocation, listMarkerInfos, setPos, refCurrentMap, place]);
  const init = async (place?: any) => {
    let map: any;
    const mapEle = document.getElementById("map") as HTMLAnchorElement;
    // eslint-disable-next-line prefer-const
    map = new google.maps.Map(mapEle, {
      center: currentLocation,
      zoom: zoom ? zoom : 13,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: false,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    /*
      === INIT Maps: Pinned marker
     */
    const pinned: any = new google.maps.Marker({
      position: currentLocation,
      map,
      visible: false,
    });
    const service: any = new google.maps.places.PlacesService(map);
    setPlaceService(service);
    setGoogleMap(map);
    setMarkerSearch(pinned);
    // INIT Maps: dialog for default marker
    const locCustomWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
      zIndex: 9999,
    });

    // INIT Maps: content of dialog default marker
    const locCustomContent = (pinEvt: any, name: string): string => {
      return `
        <div style='padding: 0px; max-width: 200px'>
          <p style="font-weight: 600;color:#464646FF;" >${name}</p>
          <p style="color:#464646FF; padding-bottom: 4px">Latitude: ${pinEvt.lat?.()}</p>
          <p style="color:#464646FF; padding-bottom: 4px">Longitude: ${pinEvt.lng?.()}</p>
          <button style='
            width: 100%;
            padding: 5px;
            background: linear-gradient(360deg, #2C57D5 -23.63%, #428DFA 111.3%);
            border-radius: 4px;
            border: 0px;
            color: white;
            font-weight: 600;

            cursor: pointer;'
            type='button' id="btn_buy">Chọn</button>
          </div>
        `;
    };
    // INIT Maps: Register parent events
    listMarkerInfos.push(new MarkerInfo(locCustomWindow, undefined, {}));
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const hideAllInfo = () => {
      listMarkerInfos.forEach((markerInfo) => {
        markerInfo.info.close();
      });
      pinned.setVisible(false);
    };

    /*
      === INIT Maps: Search box
     */

    let nameCustom: string;
    // let nameDistrict: string;
    // let nameProvince: string;
    google.maps.event.addListener(map, "click", function (e: any) {
      hideAllInfo();
      // show default marker
      // console.log(e.latLng);
      setText("");
      pinned.setPosition(e.latLng);
      pinned.setVisible(true);
      locCustomWindow.open(map, pinned);
      const eLat = {
        lat: parseFloat(e.latLng.lat()),
        lng: parseFloat(e.latLng.lng()),
      };
      // setCurrentLocation(eLat);
      const geocoder = new google.maps.Geocoder();
      void geocoder.geocode({ location: eLat }, function (results, status) {
        if (results && status === google.maps.GeocoderStatus.OK) {
          nameCustom = results[1]?.formatted_address;
          locCustomWindow.setContent(locCustomContent(e.latLng, nameCustom));
        }
      });
    });

    if (place) {
      // hideAllInfo();
      // show default marker
      // console.log(e.latLng);
      const e = place?.geometry?.location;
      pinned.setPosition(e);
      pinned.setVisible(true);
      locCustomWindow.open(map, pinned);
      const eLat = {
        lat: parseFloat(e.lat()),
        lng: parseFloat(e.lng()),
      };

      const geocoder = new google.maps.Geocoder();
      void geocoder.geocode({ location: eLat }, function (results, status) {
        if (results && status === google.maps.GeocoderStatus.OK) {
          nameCustom = place?.formatted_address;
          locCustomWindow.setContent(locCustomContent(e, nameCustom));
        }
      });
    }

    google.maps.event.addListener(locCustomWindow, "domready", function () {
      const btn = document.getElementById("btn_buy");
      if (btn) {
        btn.addEventListener("click", function () {
          setPos({
            lat: pinned.getPosition()?.lat(),
            long: pinned.getPosition()?.lng(),
            id: 0,
            name: nameCustom,
          });
        });
      }
    });

    // ----- END INIT -------------
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const checkTypeNFT = (position: MapProps): string => {
      // check type
      switch (checkTypeLocation(position, null)) {
        case MarkerType.Owned:
          return "/static/icons/icon_owner.svg";
        case MarkerType.Approved:
          return "/static/icons/icon_verify.svg";
        case MarkerType.BlackList:
          return "/static/icons/icon_black-list.svg";
        default:
          return "";
      }
    };

    let markers = listLocation?.map((position: MapProps) => {
      const { id, lat, long, name, user_full_name, user_id, user_name_owner } =
        position;
      if (!lat || !long) {
        // eslint-disable-next-line array-callback-return
        return;
      }
      const icon = {
        url: checkTypeNFT(position), // url
        scaledSize: new google.maps.Size(30, 40), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(20, 40), // anchor
      };
      const marker: any = new google.maps.Marker({
        position: { lat, lng: long },
        icon,
      });

      let content = "";
      if (user_full_name?.length || user_id || user_name_owner) {
        content = `
        <div>
            <div style='padding-top: 8px'>
              <p style="color:#081F32; font-size:16px; padding-bottom: 8px; font-weight:600; max-width: 200px"">
                ${name} 
              </p>
              <p style="color:#464646FF; padding-bottom: 4px">Latitude: ${lat}</p>
              <p style="color:#464646FF; padding-bottom: 4px">Longitude: ${long}</p>
              <p style="color:#464646FF; padding-bottom: 4px">Chủ sở hữu: ${
                user_full_name || user_id?.username
              }</p>
            </div>
          </div>
      `;
      } else {
        content = `
        <div>
            <div style='padding-top: 8px'>
              <p style="color:#081F32; font-size:16px; padding-bottom: 8px; font-weight:600; max-width: 200px">
                ${name} 
              </p>
              <p style="color:#464646FF; padding-bottom: 4px">Latitude: ${lat}</p>
              <p style="color:#464646FF; padding-bottom: 4px">Longitude: ${long}</p>
              <button onclick="test()" style='
            width: 100%;
            padding: 8px;
            background: linear-gradient(360deg, #2C57D5 -23.63%, #428DFA 111.3%);
            border-radius: 4px;
            border: 0px;
            color: white;
            font-weight: 600;
            margin-top: 20px;
            cursor: pointer;'
            type='button' id="btn_buy_existed">Chọn</button>
            </div>
          </div>
      `;
      }
      const infoWindow = new google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
      });
      listMarkerInfos.push(new MarkerInfo(infoWindow, marker, position));
      marker.addListener("click", () => {
        infoWindow.setContent(content);
        infoWindow.open(map, marker);
        google.maps.event.addListener(infoWindow, "domready", function () {
          const btn = document.getElementById("btn_buy_existed");
          if (btn) {
            btn.addEventListener("click", function () {
              hideAllInfo();
              setPos({ lat, long, id, name });
            });
          }
        });
      });
      return marker;
    });
    if (markers) {
      markers = markers.filter((item: any) => !!item);
    }
    new MarkerClusterer({ markers, map });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      handleSearch();
      listMarkerInfos.forEach((markerInfo) => {
        markerInfo.info.close();
      });
      // pinned.setVisible(false);
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSuggestions = (predictions: any, status: any) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      setPredictions([]);

      return;
    }
    setPredictions(predictions);
  };

  const handleSearch = () => {
    autocompleteService.getPlacePredictions(
      {
        input: text,
        origin: currentLocation,
        componentRestrictions: {
          country: "VN", // country: string | string[];
        },
      },
      handleSuggestions
    );
  };

  const handleClickPlace = (
    placeId: string,
    placeService: any,
    googleMap: any,
    markerSearch: any
  ) => {
    const bounds = new google.maps.LatLngBounds();
    placeService.getDetails({ placeId }, (place: any, status: any) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      if (place.geometry) {
        setText(place?.formatted_address);
        // console.log(place);

        const obj = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        googleMap.setCenter(obj);
        markerSearch.setPosition(obj);
        setCurrentLocation(obj);
        setPredictions([]);
        googleMap.fitBounds(bounds);
        setPlace(place);
      }
    });
  };

  return (
    <div className={style.wrapperMap}>
      <div className={style.pacInputView}>
        <div
          className={style.prefixSearch}
          id="submit-input"
          onClick={handleSearch}
        >
          <img
            src="/icon_search.svg"
            width={18}
            height={18}
            alt="icon_search"
          />
        </div>

        <input
          id="pac-input"
          className={style.pacInput}
          type="text"
          placeholder="Khu vực 162 Tây Hồ"
          autoComplete="off"
          value={text}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
        />
        {predictions?.length ? (
          <ul className={style.mapSearchResult}>
            {predictions.map((prediction: PredictionsProps) => (
              <li
                className={style.mapSearchResultOption}
                key={prediction.place_id}
                onClick={() => {
                  // console.log(prediction);

                  handleClickPlace(
                    prediction.place_id,
                    placeService,
                    googleMap,
                    markerSearch
                  );
                  // listMarkerInfos.forEach((markerInfo) => {
                  //   markerInfo.info.close();
                  // });
                  // pinned.setVisible(false);
                }}
              >
                {prediction.description}
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      <div className={style.mapRegister} id="map" />
    </div>
  );
}

export default MapRegisterNFT;
