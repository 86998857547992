import axios from "axios";
import instanceAxios from "./axiosConfig";
interface VerifyOtpFirebase {
  code: string;
  phoneNumber: string;
  sessionInfo: string;
}

interface CheckPassProp {
  username: string;
  otp: string;
}

interface EnterpriseProps {
  name: string;
  phone: string;
  email: string;
  address: string;
  lat: string;
  long: string;
  password: string;
  invite_code?: string;
  enterprise_image: string;
  license_image: string;
  identity_backside: string;
  identity_front: string;
  passport_image: string;
}

const API_PATH = {
  GET_USER_BY_LINK: "/users/get-user-by-link/",
  VERIFY_OTP_GOOGLE_API: "/google-api/verify-otp",
  CHECK_OTP_REGISTER: "/auth/check-otp-register",
  SEND_OTP_EMAIL: "/auth/send-otp-register",
};
const validateUser = async (id: string | undefined) => {
  const response = await instanceAxios.get(
    `${API_PATH.GET_USER_BY_LINK}${id}`
  );
  return response;
};
const verifyOtpGoogleApi = async (
  payload: VerifyOtpFirebase
) => {
  return instanceAxios.post(
    API_PATH.VERIFY_OTP_GOOGLE_API,
    {
      ...payload,
    }
  );
};

const verifyOTP = async (payload: CheckPassProp) => {
  return axios.post(`${process.env.REACT_APP_PUBLIC_URL}`, {
    query: `mutation {
      enterprise_verify_otp(input: { otp_code: "${payload.otp}", email: "${payload.username}" }) {
        message
      }
    }`,
  });
  // .then((res) => {
  //   console.log(res.data);
  // });
};

const sendOtpEmail = async (username: string) => {
  return axios.post(`${process.env.REACT_APP_PUBLIC_URL}`, {
    query: `mutation {
      enterprise_get_otp(email: "${username}") {
        message
      }
    }`,
  });
};

export const checkLogin = (ident: any) => {
  return axios.post(`${process.env.REACT_APP_PUBLIC_URL}`, {
    query: `
            mutation {
              check_ident_to_signup(input: { ident: "${ident}" }) {
                status
              }
            }      
          `,
  });
};

export const inputRefCode = (data: any) => {
  return axios.post(`${process.env.REACT_APP_PUBLIC_URL}`, {
    query: `
            mutation {
              enterprise_invite_request(input: {
                invite_code:"${data}"
              }) {
                message
              }
            }          
          `,
  });
};

const registerEnterprise = async (
  payload: EnterpriseProps
) => {
  const {
    phone,
    email,
    password,
    name,
    address,
    lat,
    long,
    enterprise_image,
    invite_code,
    identity_backside,
    identity_front,
    passport_image,
    license_image,
  } = payload;
  const body = {
    query: `mutation {
      enterprise_signup_request(
        input: {
          phone: "${phone}"
          password: "${password}"
          name: "${name}"
          email: "${email}"
          lat: ${lat}
          long: ${long}
          address: "${address}"
          enterprise_image: "${enterprise_image}"
          license_image: "${license_image}"
          invite_code: "${invite_code}"
          ${
            passport_image
              ? `passport_image: "${passport_image}"`
              : `identity_front: "${identity_front}"
              identity_backside: "${identity_backside}"`
          }
        }
      ) {
        enterprise_id
        access_token
        refresh_token
        display_name
        updated_lisence
      }
    }`,
  };
  return axios.post(
    `${process.env.REACT_APP_PUBLIC_URL}`,
    body
  );
};

export {
  validateUser,
  verifyOtpGoogleApi,
  verifyOTP,
  sendOtpEmail,
  registerEnterprise,
};
