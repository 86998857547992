import React, { useEffect, useState } from "react";
import { validateUser } from "./api/getData";
import InfoInput from "./components/InfoInput";
import Register from "./components/Register";
import "./index.css";
const TYPE = {
  PHONE: "phone",
  EMAIL: "email",
};
function App() {
  const param = new URLSearchParams(window.location.search);
  const [isValidOTP, setIsValidOTP] = useState(false);
  window.addEventListener("beforeunload", (ev) => {
    // ev.preventDefault();
    localStorage.removeItem("isValidOTP");
    // return (ev.returnValue = "Are you sure you want to close?");
  });
  const [selectedOption, setSelectedOption] = useState(
    TYPE.PHONE
  );
  const [username, setUsername] = useState("");
  const [data, setData] = useState<{
    full_name: string;
    id: number;
  }>();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    validateUser(param.get("ref_code")?.toString())
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
    console.log(
      `${process.env.REACT_APP_URL_WEB_CMS} + register`
    );

    window.location.href =
      `${process.env.REACT_APP_URL_WEB_CMS}` +
      `register` +
      `${
        param.get("ref_code")
          ? `?ref_code=${param.get("ref_code")?.toString()}`
          : ""
      }`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = () => {
    localStorage.removeItem("isValidOTP");
    window.location.href = `${process.env.REACT_APP_URL_WEB_CMS}`;
  };
  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          justifyContent: isValidOTP
            ? "space-between"
            : "flex-end",
          alignItems: "center",
          padding: "32px",
        }}
      >
        {isValidOTP && (
          <button
            className="btn-login btn-back"
            onClick={() => {
              localStorage.removeItem("isValidOTP");
              setUsername("");
              setIsValidOTP(false);
            }}
          >
            <img src="./back.svg" alt="icon" />
          </button>
        )}
        <button className="btn-login" onClick={handleLogin}>
          Đăng nhập
        </button>
      </div>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <div className="form-input">
            <img
              src="LocaMeet.png"
              alt="locameet"
              className="logo"
            />
            <div className="title">
              Đăng ký tài khoản doanh nghiệp
            </div>

            {!isValidOTP ? (
              <InfoInput
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                username={username}
                setUsername={setUsername}
                setIsValidOTP={setIsValidOTP}
                name={data?.full_name}
              />
            ) : (
              <Register
                option={selectedOption}
                username={username}
                id={param.get("ref_code")?.toString()}
              />
              // <Test />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
