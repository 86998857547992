import React, { SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import { firebase } from "../firebase/config";
import {
  convertPhoneVietnameseDotFirst,
  replacePhoneFirebase,
} from "../utils/common";
import { toast } from "react-toastify";

export function useGetOTPFireBase(
  value: string,
  condition: boolean,
  setIsOpenModal: React.Dispatch<SetStateAction<boolean>>
) {
  const [verifyCaptcha, setVerifyCaptcha] = useState<string>("");
  let debouncePhone = useDebounce(value, 500);
  useEffect(() => {
    try {
      if (convertPhoneVietnameseDotFirst(value)?.length === 10 && condition) {
        let verify = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            type: "image",
            badge: "bottomleft",
          }
        );

        firebase
          .auth()
          .signInWithPhoneNumber(replacePhoneFirebase(debouncePhone), verify)
          .then((result: any) => {
            setVerifyCaptcha(result?.verificationId);
            setIsOpenModal(true);
          });
        // .catch(() => {
        //   toast.warning("Đã quá số lần gửi, vui lòng thử lại sau.", {
        //     theme: "colored",
        //   });
        // });
      } else {
        setVerifyCaptcha("");
      }
    } catch (err: any) {
      toast.warning("Đã quá số lần gửi, vui lòng thử lại sau.", {
        theme: "colored",
      });
    }
  }, [debouncePhone, condition, setIsOpenModal]);
  return verifyCaptcha;
}
