// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyD8-o10wI1uKMustZ-mLSNxnrSYezj_Kcw",
  authDomain: "smooth-pen-362407.firebaseapp.com",
  projectId: "smooth-pen-362407",
  storageBucket: "smooth-pen-362407.appspot.com",
  messagingSenderId: "615674978299",
  appId: "1:615674978299:web:4e6e2a252b6b9f3996f55e",
  measurementId: "G-7E8EML90ZX",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export { firebase };
