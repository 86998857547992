import axios from 'axios';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_WEB,
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

export default instanceAxios;
