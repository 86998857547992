export function convertPhoneVietnameseDotFirst(
  value: string
) {
  if (!value) return "";
  if (value.slice(0, 2).toString() === "84")
    return "0" + value.slice(2);
  else return value;
}

export function replacePhoneFirebase(value: string) {
  if (!value) return "";
  if (value.slice(0, 2).toString() === "84")
    return "+" + value;
  const sliceFirst = value.slice(1);
  return "+84" + sliceFirst;
}

export const validateEmail = (value: string) => {
  const emailRegex =
    /^[a-zA-Z0-9-._]{4,}@[a-zA-Z0-9-_]+\.(vn|com|com.vn|net)$/g;
  if (value.length === 0) {
    return "";
  }
  if (emailRegex.test(value) || value.length === 0) {
    return "";
  }
  return "Vui lòng nhập đúng định dạng email";
};
export const validatePhone = (value: string) => {
  const re = /^(0[3|5|7|8|9])+([0-9]{8})+$\b/g;
  if (!value) {
    return "";
  }
  if (re.test(value) || value.length === 0) {
    return "";
  }
  return "Vui lòng nhập đúng định dạng số điện thoại";
};
